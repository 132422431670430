import { Component } from 'solid-js';
import './about-page.scss';
import { AboutPageHTMLProperties } from './about-page';

export const AboutPageHTML: Component = (properties) => {
    const {
    } = properties as AboutPageHTMLProperties

    return (
        <div class="about-page">
            <div class="about-page-content">
                <div class="banner-image" style={{ "background-image": "url(/images/banners/about-banner.jpg)" }} title="Over 30 years of fun! banner"></div>
                <div class="text-container">
                    <h1>About us</h1>
                    <p>The Microsoft Casual Games Studio has revolutionized the most popular genres of casual games, bringing a fresh twist to classic favorites that have been delighting fans for OVER 30 years! With features like Daily Challenges, Events, Collections, Achievements and Rewards, we are dedicated to delivering great casual game experiences for players of all ages.</p>
                    <p>As a collaborative team who loves making games as much as we love playing them, we work hard to add new content and excitement for players to enjoy around the world. Whether you play on Windows 11, iOS, Android, or Web, we believe in building community through fun. When everyone plays, everyone wins!</p>
                    <p>We are thrilled to have celebrated the 30th Anniversary of two of our most beloved games - Microsoft Minesweeper (1989) and Microsoft Solitaire (1990). Microsoft Solitaire remains the most played video game of ALL TIME! Inducted into the Video Game Hall of Fame in 2019, millions of players choose Microsoft Casual Games because we offer the best experiences for anyone from age 8 to 108. Simple rules and straightforward gameplay deliver fun for everyone.</p>
                    <p>Thank you for your continued passion and love of play! We are proud to be part of your daily choice of games.</p>
                </div>
            </div>
        </div>
    );
};

import { Accessor, Component, createSignal, onMount } from 'solid-js';
import { FooterHTML } from './footer-component.html';
import { getUserLocationData } from '../../services/utilities';

export const Footer: Component = () => {
    const copyrightYear: string = new Date().getFullYear().toString();
    const [showYourPrivacyChoicesLink, setShowYourPrivacyChoicesLink] = createSignal(false);

    onMount(async () => {
        initialize();
    });

    function initialize() {
        showOrHidePrivacyLink();
    }
    function showOrHidePrivacyLink() {
        getUserLocationData()
            .then((locationData: unknown) => {
                const data = locationData as { country: string; };
                if (data.country?.toUpperCase?.() === 'US') {
                    setShowYourPrivacyChoicesLink(true);
                }
            })
            .catch((errorData: any) => {
                // Show the privacy link if anything breaks
                setShowYourPrivacyChoicesLink(true);
                console.error(errorData);
            });
    }

    return FooterHTML({ copyrightYear, showYourPrivacyChoicesLink } as FooterHTMLProperties);
};

export interface FooterHTMLProperties {
    copyrightYear: string,
    showYourPrivacyChoicesLink: Accessor<boolean>,
};
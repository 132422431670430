let userLocationData: any;

export function getUserLocationData() {
    return new Promise((resolve, reject) => {
        if (userLocationData) { resolve(userLocationData); return; }

        fetch(import.meta.env.VITE_USER_LOCATION_DATA_API_URL, {
            headers: { 'x-functions-key': import.meta.env.VITE_USER_LOCATION_DATA_API_KEY },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    reject(new Error(`getUserLocationData() failed with status: ${response.status}`));
                }
            })
            .then(data => {
                userLocationData = data; // Cache the response
                resolve(data);
            })
            .catch(error => {
                reject(new Error(`getUserLocationData() failed with status: ${error.message}`));
            });
    });
}

export function focusElementById(querySelector: string) {
    const element = document.getElementById(querySelector);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' } as ScrollIntoViewOptions);
        setTimeout(() => {
            const focusableElements: HTMLElement[] = getKeyboardFocusableElements(element) as HTMLElement[];
            if (focusableElements.length > 0) (focusableElements[0]).focus();
        }, 0);
    } else {
        console.warn(`Element does not exist with id: ${querySelector}`);
    }
}

export function getKeyboardFocusableElements(element: HTMLElement): Element[] {
    return Array.from(element.querySelectorAll(
        'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
    )).filter(
        (foundElement) => !foundElement.hasAttribute('disabled') && !foundElement.getAttribute('aria-hidden'),
    );
}
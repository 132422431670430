import { createSignal } from 'solid-js';
import applicationInsightsService from './application-insights-service';

declare global {
    interface Window {
        WcpConsent: any;
    }
}

// export const [showYoutubeVideo, setShowYoutubeVideo] = createSignal(false);
export const [showFooterLink, setShowFooterLink] = createSignal(false);

class CookieConsentService {
    private siteConsent: any;

    private wcpConsentData = {
        required: false,
        analytics: false,
        socialMedia: false,
        advertising: false
    };

    constructor() {
        this.initialize();
    }

    initialize() {
        this.initializeEventListeners();
    }

    initializeEventListeners() {
        if (document.readyState === 'complete') {
            this.initCookieConsentBanner();
        } else {
            document.addEventListener('DOMContentLoaded', (event) => {
                this.initCookieConsentBanner();
            });
        }
    }

    initCookieConsentBanner() {
        var bannerElement = document.createElement('div');
        bannerElement.id = 'cookieConsentBanner';
        document.body.insertBefore(bannerElement, document.body.firstChild);

        // The `window.WcpConsent` is established by `wcp-consent.js` in the `index.html` <head>.
        if (window.WcpConsent) {
            window.WcpConsent.init(
                'en-US',
                'cookieConsentBanner',
                this.onWcpConsentInitialize.bind(this),
                this.onWcpConsentChanged.bind(this)
            );
        }
    }

    setWcpConsent(required: boolean, analytics: boolean, socialMedia: boolean, advertising: boolean) {
        this.wcpConsentData = {
            required: required,
            analytics: analytics,
            socialMedia: socialMedia,
            advertising: advertising
        };

        window.postMessage(
            {
                name: 'setWcpConsent',
                userConsent: this.wcpConsentData,
            },
            '*'
        );
        this.logConsent('setWcpConsent', this.wcpConsentData);

        // if (required && socialMedia && advertising)
        //     setShowYoutubeVideo(true);
        // else
        //     setShowYoutubeVideo(false);
    }

    logConsent(name: string, wcpConsentData: any) {
        if (applicationInsightsService) {
            applicationInsightsService.trackEvent({ name: name }, wcpConsentData);
        }
    }

    manageCookies(event: any) {
        event.preventDefault();
        this.siteConsent.manageConsent();
    }

    onWcpConsentChanged(userConsent: any) {
        this.setWcpConsent(
            userConsent.Required,
            userConsent.Analytics,
            userConsent.SocialMedia,
            userConsent.Advertising
        );
    }

    onWcpConsentInitialize(error: any, newSiteConsent: any) {
        // console.log(`Wcp Consent Initialized`, newSiteConsent);
        if (!error && newSiteConsent) {
            this.siteConsent = newSiteConsent;
            var userConsent = this.siteConsent.getConsent();

            this.setWcpConsent(
                userConsent.Required,
                userConsent.Analytics,
                userConsent.SocialMedia,
                userConsent.Advertising
            );
            if (this.siteConsent.isConsentRequired) {
                setShowFooterLink(true);
            }
        } else {
            this.logConsent('wcpConsentError', error);
        }
    }
}

const cookieConsentService = new CookieConsentService();
export default cookieConsentService;

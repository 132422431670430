import { render } from 'solid-js/web';
import { Router, Route } from '@solidjs/router';

import { version } from '../package.json';

import './app.scss';

import { HomePage } from './pages/home/home-page';
import { GamePage } from './pages/game/game-page';
import { AboutPage } from './pages/about/about-page';
import { MultipleGameQR } from './pages/multiple-game-qr/multiple-game-qr-page';
import { SingleGameQR } from './pages/single-game-qr/single-game-qr-page';
import { OSSNotice } from './pages/oss-notice/oss-notice-page';
import { Header } from './components/header/header-component';
import { Footer } from './components/footer/footer-component';

// Grab the root html element for the router
const rootElement = document.getElementById('root-element');
if (import.meta.env.DEV && !(rootElement instanceof HTMLElement)) {
    throw new Error('Failed to initialize site. Root element not found.');
}

// URL router
render(
    () => (
        <div class="app">
            <Header></Header>
            <main id='main'>
                <Router>
                    <Route path="/" component={HomePage} />
                    <Route path="/about" component={AboutPage} />

                    <Route path="/oss-notice" component={OSSNotice} />

                    <Route path="/promo/mobile" component={MultipleGameQR} />
                    <Route path="/promo/:gameId" component={SingleGameQR} />

                    <Route path="/:gameId" component={GamePage} />

                    {/* Send any 404's to the homepage */}
                    <Route path="*404" component={HomePage} />
                </Router>
            </main>
            <Footer></Footer>
        </div>
    ),
    rootElement!
);

// Create version header tag
const metaTag = document.createElement('meta');
metaTag.setAttribute('name', 'app-version');
metaTag.setAttribute('content', version);
document.head.appendChild(metaTag);
import { GamesData } from "./single-game-qr-page.model";

export const gamesData: GamesData = {
    'solitairemobileqr': {
        name: 'Microsoft Solitaire Collection',
        images: {
            qrCode: '/images/qr-codes/solitaire-singular-qr-code.svg',
            gameIcon: '/images/game-icons/game-icon-solitaire.png',
            phoneLarge: '/images/big-phones/big-phone-solitaire.png',
        }
    },
    'mahjongmobileqr': {
        name: 'Mahjong by Microsoft',
        images: {
            qrCode: '/images/qr-codes/mahjong-singular-qr-code.svg',
            gameIcon: '/images/game-icons/game-icon-mahjong.png',
            phoneLarge: '/images/big-phones/big-phone-mahjong.png',
        }
    },
    'sudokumobileqr': {
        name: 'Microsoft Sudoku',
        images: {
            qrCode: '/images/qr-codes/sudoku-onelink-qr-code.svg',
            gameIcon: '/images/game-icons/game-icon-sudoku.png',
            phoneLarge: '/images/big-phones/big-phone-sudoku.png',
        }
    },
    'wordamentmobileqr': {
        name: 'Wordament',
        images: {
            qrCode: '/images/qr-codes/wordament-onelink-qr-code.svg',
            gameIcon: '/images/game-icons/game-icon-wordament.png',
            phoneLarge: '/images/big-phones/big-phone-wordament.png',
        }
    },
    // 'jigsawmobileqr': {
    //     name: 'Microsoft Jigsaw',
    //     images: {
    //         qrCode: '/images/qr-codes/jigsaw-qr-code.svg',
    //         gameIcon: '/images/game-icons/game-icon-jigsaw.png',
    //         phoneLarge: '/images/big-phones/big-phone-jigsaw.png',
    //     }
    // },
    // 'minesweepermobileqr': {
    //     name: 'Microsoft Minesweeper',
    //     images: {
    //         qrCode: '/images/qr-codes/minesweeper-qr-code.svg',
    //         gameIcon: '/images/game-icons/game-icon-minesweeper.png',
    //         phoneLarge: '/images/big-phones/big-phone-minesweeper.png',
    //     }
    // },
    // 'treasurehuntmobileqr': {
    //     name: 'Microsoft Treasure Hunt',
    //     images: {
    //         qrCode: '/images/qr-codes/treasure-hunt-qr-code.svg',
    //         gameIcon: '/images/game-icons/game-icon-treasure-hunt.png',
    //         phoneLarge: '/images/big-phones/big-phone-treasure-hunt.png',
    //     }
    // },
    // 'ultimatewordgames': {
    //     name: 'Microsoft Ultimate Word Games',
    //     images: {
    //         qrCode: '/images/qr-codes/ultimate-word-games-qr-code.svg',
    //         gameIcon: '/images/game-icons/game-icon-ultimate-word-games.png',
    //         phoneLarge: '/images/big-phones/big-phone-ultimate-word-games.png',
    //     }
    // }
}
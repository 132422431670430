import { Component, Show } from 'solid-js';
import './footer-component.scss';
import { FooterHTMLProperties } from './footer-component';
import cookieConsentService, { showFooterLink } from '../../services/cookie-consent-service';

export const FooterHTML: Component = (properties) => {
    const {
        copyrightYear,
        showYourPrivacyChoicesLink,
    } = properties as FooterHTMLProperties;

    return (
        <footer id='footer'>
            <div class="footer-left">
                <div class="microsoft-logo">
                    <img alt="Microsoft" src="/images/msft-logo.png" />
                    <span class="microsoft-text">Microsoft</span>
                </div>

                <span class="microsoft-copyright">&copy {copyrightYear} Microsoft</span>
            </div>

            <div class="footer-center">
                <a href="https://aka.ms/MCG.comContactUs" target="_blank" title="Contact Us">Contact Us</a>
                <a href="https://windows.microsoft.com/en-us/windows/microsoft-services-agreement" target="_blank" title="Terms of Use">Terms of Use</a>
                <a href="/oss-notice" target="_blank" title="Third Party Software Notice">Third Party Software Notice</a>
                <a href="https://www.microsoft.com/trademarks/" target="_blank" title="Trademarks">Trademarks</a>
                <a href="https://aka.ms/MCG.comSupport" target="_blank" title="Support">Support</a>

                <a href="https://go.microsoft.com/fwlink/?LinkId=521839" target="_blank" title="Privacy & Cookies">Privacy & Cookies</a>
                <a href="https://go.microsoft.com/fwlink/?linkid=2259814" target="_blank" title="Consumer Health Privacy">Consumer Health Privacy</a>
                <Show when={showYourPrivacyChoicesLink()}>
                    <a class="privacy-policy-text" href="https://aka.ms/YourCaliforniaPrivacyChoices" target="_blank" title="Your Privacy Choices">
                        Your Privacy Choices
                        <img src="/images/privacy-options.png" alt="Privacy choices logo" />
                    </a>
                </Show>
                <Show when={showFooterLink()}>
                    <a href="#" onClick={(event) => { cookieConsentService.manageCookies(event) }} class="manage-cookies" title="Manage Cookies">Manage Cookies</a>
                </Show>
            </div>

            <div class="footer-right">
                <a href="https://aka.ms/MCG_Home_Twitter" title="Visit us on Twitter" target="_blank">
                    <img class="social-icon" src="/images/social-media/twitter-icon.svg" alt="Twitter Icon" />
                </a>
                <a href="https://aka.ms/MCG_Home_Facebook" title="Visit us on Facebook" target="_blank">
                    <img class="social-icon" src="/images/social-media/facebook-icon.svg" alt="Facebook Icon" />
                </a>
                <a href="https://aka.ms/MCG_Home_YouTube" title="Visit us on Youtube" target="_blank">
                    <img class="social-icon" src="/images/social-media/youtube-icon.svg" alt="Youtube Icon" />
                </a>
                <a href="https://aka.ms/MCG_Home_Instagram" title="Visit us on Instagram" target="_blank">
                    <img class="social-icon" src="/images/social-media/instagram-icon.svg" alt="Instagram Icon" />
                </a>
            </div>
        </footer>
    );
};
import { Component, Show } from 'solid-js';
import './multiple-game-qr-page.scss';
import { MultipleGameQRPageHTMLProperties } from './multiple-game-qr-page';
import { gamesData } from './multiple-game-qr-page.data';

export const MultipleGameQRPageHTML: Component = (properties) => {
    const {
        selectedGame,
        setSelectedGame,
    } = properties as MultipleGameQRPageHTMLProperties

    return (
        <main class="multiple-game-qr-page">
            <h1 class="heading-1">Start playing on your iOS or Android phone today!</h1>
            <div class="sub-heading">Select the mobile game you would like to play. Scan the QR code to download the game FREE from the App Store.</div>
            <div class="game-buttons">
                <button class={"button_solitaire" + (selectedGame() === 'solitaire' ? " button_selected" : "")}
                    onClick={() => { setSelectedGame('solitaire'); }} title={"View " + gamesData['solitaire'].name + " QR Code"}>
                    <Show when={selectedGame() === 'solitaire'}>
                        <div class="up-arrow-center-container"><div class="up-arrow-relative-container"><div class="up-arrow"></div></div></div>
                    </Show>
                    <img src={gamesData['solitaire'].images.gameIcon} alt="Solitaire icon" />
                    <p class="text">Microsoft Solitaire Collection</p>
                </button>
                <button class={"button_sudoku" + (selectedGame() === 'sudoku' ? " button_selected" : "")}
                    onClick={() => { setSelectedGame('sudoku'); }} title={"View " + gamesData['sudoku'].name + " QR Code"}>
                    <Show when={selectedGame() === 'sudoku'}>
                        <div class="up-arrow-center-container"><div class="up-arrow-relative-container"><div class="up-arrow"></div></div></div>
                    </Show>
                    <img src={gamesData['sudoku'].images.gameIcon} alt="Sudoku icon" />
                    <p class="text">Microsoft Sudoku</p>
                </button>
                <button class={"button_mahjong" + (selectedGame() === 'mahjong' ? " button_selected" : "")}
                    onClick={() => { setSelectedGame('mahjong'); }} title={"View " + gamesData['mahjong'].name + " QR Code"}>
                    <Show when={selectedGame() === 'mahjong'}>
                        <div class="up-arrow-center-container"><div class="up-arrow-relative-container"><div class="up-arrow"></div></div></div>
                    </Show>
                    <img src={gamesData['mahjong'].images.gameIcon} alt="Mahjong icon" />
                    <p class="text">Mahjong by Microsoft</p>
                </button>
                <button class={"button_wordament" + (selectedGame() === 'wordament' ? " button_selected" : "")}
                    onClick={() => { setSelectedGame('wordament'); }} title={"View " + gamesData['wordament'].name + " QR Code"}>
                    <Show when={selectedGame() === 'wordament'}>
                        <div class="up-arrow-center-container"><div class="up-arrow-relative-container"><div class="up-arrow"></div></div></div>
                    </Show>
                    <img src={gamesData['wordament'].images.gameIcon} alt="Wordament icon" />
                    <p class="text">Microsoft Wordament</p>
                </button>
            </div>

            <Show when={selectedGame() && gamesData[selectedGame()]}>
                <div class="qr-code-area">

                    <div class="grid">
                        <div class="instruction-list">
                            <h2 class="heading-2">How to use a QR Code:</h2>
                            <ul>
                                <li class="text">Open your camera or QR reader on your mobile device.</li>
                                <li class="text">Align your camera with the QR Code.</li>
                                <li class="text">Click the link to open the game in your device's App Store.</li>
                            </ul>
                        </div>
                        <img class="qr-code" src={gamesData[selectedGame()].images.qrCode} alt={`QR code for "${gamesData[selectedGame()].name}" mobile game download`} />
                    </div>
                </div>
            </Show>

            <div class="disclaimer">
                By clicking the QR code URL, you agree to direct your mobile device to the relevant game page in the App Store associated with your mobile device. Standard rates may apply. This consent is not necessary to use Microsoft Casual Games on mobile.
            </div>
        </main>
    );
};

import { Component, onMount } from 'solid-js';
import { HomepageTilesHTML } from './homepage-tiles-component.html';

export const HomepageTiles: Component = () => {
    onMount(async () => {
        initialize();
    });

    function initialize() {
    }

    return HomepageTilesHTML({ } as HomepageTilesHTMLProperties);
};

export interface HomepageTilesHTMLProperties {
};
import { Component, createEffect, onMount } from 'solid-js';
import { HomePageHTML } from './home-page.html';
import { useLocation } from '@solidjs/router';
import applicationInsightsService from '../../services/application-insights-service';

export const HomePage: Component = () => {

    onMount(async () => {
        initialize();
    });

    function initialize() {
        applicationInsightsService.trackPageView();
    }

    return HomePageHTML({} as HomePageHTMLProperties);
};

export interface HomePageHTMLProperties {
};
import { Accessor, Component, Setter, createEffect, createSignal, onMount } from 'solid-js';
import { Params, useParams } from '@solidjs/router';
import { GamePageHTML } from './game-page.html';
import applicationInsightsService from '../../services/application-insights-service';

export const GamePage: Component = () => {
    const urlParameters = useParams();
    const [selectedScreenshot, setSelectedScreenshot] = createSignal(0);

    onMount(async () => {
        initialize();
    });

    function initialize() {
        applicationInsightsService.trackPageView();
        initializeStateChangeEvents();
    }

    function initializeStateChangeEvents() {
        createEffect(() => {
            if (urlParameters.gameId) { setSelectedScreenshot(0); }
        });
    }

    return GamePageHTML({ urlParameters, selectedScreenshot, setSelectedScreenshot } as GamePageHTMLProperties);
};

export interface GamePageHTMLProperties {
    urlParameters: Params,
    selectedScreenshot: Accessor<number>,
    setSelectedScreenshot: Setter<number>,
};

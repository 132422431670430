import { GamesData } from "./multiple-game-qr-page.model";

export const gamesData: GamesData = {
    'solitaire': {
        name: 'Microsoft Solitaire Collection',
        images: {
            qrCode: '/images/qr-codes/solitaire-singular-qr-code.svg',
            gameIcon: '/images/game-icons/game-icon-solitaire.png',
        },
    },
    'sudoku': {
        name: 'Microsoft Sudoku',
        images: {
            qrCode: '/images/qr-codes/sudoku-onelink-qr-code.svg',
            gameIcon: '/images/game-icons/game-icon-sudoku.png',
        },
    },
    'mahjong': {
        name: 'Mahjong by Microsoft',
        images: {
            qrCode: '/images/qr-codes/mahjong-singular-qr-code.svg',
            gameIcon: '/images/game-icons/game-icon-mahjong.png',
        },
    },
    'wordament': {
        name: 'Wordament',
        images: {
            qrCode: '/images/qr-codes/wordament-onelink-qr-code.svg',
            gameIcon: '/images/game-icons/game-icon-wordament.png',
        },
    },
    // 'ultimatewordgames': {
    //     name: 'Microsoft Ultimate Word Games',
    //     images: {
    //         qrCode: '/images/qr-codes/ultimate-word-games-qr-code.png',
    //         gameIcon: '/images/game-icons/game-icon-ultimate-word-games.png',
    //     },
    // }
    // 'jigsaw': {
    //     name: 'Microsoft Jigsaw',
    //     images: {
    //         qrCode: '/images/qr-codes/jigsaw-qr-code.png',
    //         gameIcon: '/images/game-icons/jigsaw-icon.png',
    //     },
    // },
    // 'minesweeper': {
    //     name: 'Microsoft Minesweeper',
    //     images: {
    //         qrCode: '/images/qr-codes/minesweeper-qr-code.png',
    //         gameIcon: '/images/game-icons/game-icon-minesweeper.png',
    //     },
    // },
}
import { Component, onMount } from 'solid-js';
import { OSSNoticeHTML } from './oss-notice-page.html';
import applicationInsightsService from '../../services/application-insights-service';

export const OSSNotice: Component = () => {

    onMount(async () => {
        initialize();
    });

    function initialize() {
        applicationInsightsService.trackPageView();
    }

    return OSSNoticeHTML({} as OSSNoticeHTMLProperties);
};

export interface OSSNoticeHTMLProperties {
};
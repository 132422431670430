import { Component, onMount } from 'solid-js';
import './single-game-qr-page.scss';
import { Params, useParams } from '@solidjs/router';
import { SingleGameQRHTML } from './single-game-qr-page.html';
import applicationInsightsService from '../../services/application-insights-service';

export const SingleGameQR: Component = () => {
    const urlParameters = useParams();

    onMount(async () => {
        initialize();
    });

    function initialize() {
        applicationInsightsService.trackPageView();
    }

    return SingleGameQRHTML({ urlParameters } as SingleGameQRHTMLProperties);
};

export interface SingleGameQRHTMLProperties {
    urlParameters: Params,
};
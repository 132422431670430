import { Component } from 'solid-js';
import './single-game-qr-page.scss';
import { SingleGameQRHTMLProperties } from './single-game-qr-page';
import { gamesData } from './single-game-qr-page.data';

export const SingleGameQRHTML: Component = (properties) => {
    const {
        urlParameters,
    } = properties as SingleGameQRHTMLProperties

    return (
        <main class="single-game-qr-page">
            <div class="header">
                <img class="icon" src={gamesData[urlParameters.gameId].images.gameIcon} alt={gamesData[urlParameters.gameId].name+' icon'}/>
                <h1>Start playing on your iOS or Android phone today!</h1>
            </div>

            <div class="container">
                <div class="phone-large-container">
                    <img class="phone-large" src={gamesData[urlParameters.gameId].images.phoneLarge} alt={gamesData[urlParameters.gameId].name+' phone preview'}/>
                </div>

                <div class="information-column">
                    <div class='information-row'>
                        <div class="instruction-list">
                            <h2>How to use a QR Code:</h2>
                            <ul>
                                <li><p>Open your camera or QR reader on your mobile device.</p></li>
                                <li><p>Align your camera with the QR Code.</p></li>
                                <li><p>Click the link to open the game in your device's App Store.</p></li>
                            </ul>
                        </div>

                        <img src={gamesData[urlParameters.gameId].images.qrCode} class="qr-code" alt={gamesData[urlParameters.gameId].name+' qr code'}/>
                    </div>

                    <div class="disclaimer">
                        By clicking the QR code URL, you agree to direct your mobile device to the <span>{gamesData[urlParameters.gameId] ? gamesData[urlParameters.gameId].name : 'relevant'}</span> game page in the App Store associated with your mobile device. Standard rates may apply. This consent is not necessary to use Microsoft Casual Games on mobile.
                    </div>
                </div>
            </div>

        </main >
    );
};

import { Component } from 'solid-js';
import './home-page.scss';
import { HomepageTiles } from '../../components/homepage-tiles/homepage-tiles-component';
import { HomePageHTMLProperties } from './home-page';

export const HomePageHTML: Component = (properties) => {
    const {
    } = properties as HomePageHTMLProperties

    return (
        <div class="home-page">
            <div class="home-page-content">
                <a href='/promo/mobile/' target='_blank' title={"Play Microsoft Games on Mobile"} aria-label={"Play Microsoft Games on Mobile"}>
                    <div class="banner-image" style={{ "background-image": "url(/images/banners/play-on-mobile-banner.jpg)" }}></div>
                </a>

                {/* <a href='https://aka.ms/mcghome' target='_blank' title={"Play Microsoft Games on Mobile"} aria-label={"Play Microsoft Games on Mobile"}>
                    <img src="/images/banners/play-on-mobile-banner.jpg" role="presentation" />
                </a> */}
                <h1 class="screen-reader-accessible-but-visually-hidden">Welcome to Microsoft Casual Games</h1>
            </div>
            <HomepageTiles></HomepageTiles>
        </div>
    );
};

import { Component } from 'solid-js';
import './header-component.scss';
import { HeaderHTMLProperties } from './header-component';
import { focusElementById } from '../../services/utilities';

export const HeaderHTML: Component = (properties) => {
    const {
        openSubmenu, closeSubmenu, toggleSubmenu, touchSubmenuUp, touchSubmenuDown, onSubmenuFocusOutEvent
    } = properties as HeaderHTMLProperties;

    return (
        <header id='header'>
            <div class="microsoft-casual-games-logo">
                <a class="anchor" href="/">
                    <img class="icon" src="/images/logo-cards-cropped.png" alt="Cards Logo" />
                    <p class="h1">Microsoft Casual Games</p>
                </a>
            </div>

            <div class="skip-to-content-button-center-container">
                <div class="skip-to-content-button-relative-container">
                    <button class="skip-to-content-button" onClick={() => { focusElementById("main") }} role="link" tabindex="0">
                        <p>Skip to main content</p>
                    </button>

                    <button class="skip-to-content-button" onClick={() => { focusElementById("footer") }} role="link" tabindex="0">
                        <p>Skip to footer content</p>
                    </button>
                </div>
            </div>

            <ul class="menu">
                <li class="menu-link">
                    <a href="/">home</a>
                </li>

                <li class="menu-link" onMouseEnter={openSubmenu} onMouseLeave={closeSubmenu} onFocusOut={onSubmenuFocusOutEvent}>
                    <button id='header-games-submenu-toggle-button' onTouchStart={touchSubmenuDown} onTouchEnd={touchSubmenuUp}
                        onClick={toggleSubmenu} class="submenu-toggle-button menu-button" aria-live="assertive" aria-expanded="false" tabindex="0" >
                        games
                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="12 12 24 24"><path fill="white" d="m24 30.75-12-12 2.15-2.15L24  26.5l9.85-9.85L36 18.8Z" /></svg>
                    </button>

                    <div id='header-games-submenu' class="games-submenu" aria-hidden="true" tabindex="-1">
                        <div class='list-wrapper'>
                            <ul>
                                <li class="list-item-solitaire">
                                    <a href="/solitaire" tabindex="-1" onClick={() => { closeSubmenu(); focusElementById("root-element") }} >Solitaire Collection</a>
                                </li>
                                <li class="list-item-mahjong">
                                    <a href="/mahjong" tabindex="-1" onClick={() => { closeSubmenu(); focusElementById("root-element") }}>Mahjong</a>
                                </li>
                                <li class="list-item-jigsaw">
                                    <a href="/jigsaw" tabindex="-1" onClick={() => { closeSubmenu(); focusElementById("root-element") }}>Jigsaw</a>
                                </li>
                                <li class="list-item-minesweeper">
                                    <a href="/minesweeper" tabindex="-1" onClick={() => { closeSubmenu(); focusElementById("root-element") }}>Minesweeper</a>
                                </li>
                                <li class="list-item-sudoku">
                                    <a href="/sudoku" tabindex="-1" onClick={() => { closeSubmenu(); focusElementById("root-element") }}>Sudoku</a>
                                </li>
                                <li class="list-item-ultimate-word">
                                    <a href="/ultimatewordgames" tabindex="-1" onClick={() => { closeSubmenu(); focusElementById("root-element") }}>Ultimate Word Games</a>
                                </li>
                                <li class="list-item-treasure-hunt">
                                    <a href="/treasurehunt" tabindex="-1" onClick={() => { closeSubmenu(); focusElementById("root-element") }}>Treasure Hunt</a>
                                </li>
                                <li class="list-item-wordament">
                                    <a href="/wordament" tabindex="-1" onClick={() => { closeSubmenu(); focusElementById("root-element") }}>Wordament</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="menu-link">
                    <a href="/about">about</a>
                </li>
                <li class="menu-link">
                    <a href="https://aka.ms/MCGShop" target="_blank">shop</a>
                </li>
            </ul>
        </header>
    );
};

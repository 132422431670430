import { Component } from 'solid-js';
import './homepage-tiles-component.scss';
import { HomepageTilesHTMLProperties } from './homepage-tiles-component';
import { focusElementById } from '../../services/utilities';

export const HomepageTilesHTML: Component = (properties) => {
    const { } = properties as HomepageTilesHTMLProperties;

    return (
        <div class="homepage-tiles-component">
            <div class='tiles'>
                <div class='tile'>
                    <a onClick={() => { focusElementById("root-element") }} href="/solitaire" title="View Solitaire" aria-label="View Solitaire">
                        <img alt="Solitaire" src="/images/game-tiles/solitaire-tile.webp" aria-hidden="true" />
                    </a>
                </div>
                <div class='tile'>
                    <a onClick={() => { focusElementById("root-element") }} href="/mahjong" title="View Mahjong" aria-label="View Mahjong">
                        <img alt="Mahjong" src="/images/game-tiles/mahjong-tile.webp" aria-hidden="true" />
                    </a>
                </div>
                <div class='tile'>
                    <a onClick={() => { focusElementById("root-element") }} href="/jigsaw" title="View Jigsaw" aria-label="View Jigsaw">
                        <img alt="Jigsaw" src="/images/game-tiles/jigsaw-tile.webp" aria-hidden="true" />
                    </a>
                </div>
                <div class='tile'>
                    <a onClick={() => { focusElementById("root-element") }} href="/minesweeper" title="View Minesweeper" aria-label="View Minesweeper">
                        <img alt="Minesweeper" src="/images/game-tiles/minesweeper-tile.webp" aria-hidden="true" />
                    </a>
                </div>
                <div class='tile'>
                    <a onClick={() => { focusElementById("root-element") }} href="/sudoku" title="View Sudoku" aria-label="View Sudoku">
                        <img alt="Sudoku" src="/images/game-tiles/sudoku-tile.webp" aria-hidden="true" />
                    </a>
                </div>
                <div class='tile'>
                    <a onClick={() => { focusElementById("root-element") }} href="/ultimatewordgames" title="View Ultimate Word Games" aria-label="View Ultimate Word Games">
                        <img alt="Ultimate Word Games" src="/images/game-tiles/ultimate-word-games-tile.webp" aria-hidden="true" />
                    </a>
                </div>
                <div class='tile'>
                    <a onClick={() => { focusElementById("root-element") }} href="/treasurehunt" title="View Treasure Hunt" aria-label="View Treasure Hunt">
                        <img alt="Treasure Hunt" src="/images/game-tiles/treasure-hunt-tile.webp" aria-hidden="true" />
                    </a>
                </div>
                <div class='tile'>
                    <a onClick={() => { focusElementById("root-element") }} href="https://aka.ms/Jewel_MCGtile" target="_blank" title="Play Microsoft Jewel 2" aria-label="Play Microsoft Jewel 2">
                        <img alt="Microsoft Jewel" src="/images/game-tiles/jewel-2-tile.webp" aria-hidden="true" />
                    </a>
                </div>
                <div class='tile'>
                    <a onClick={() => { focusElementById("root-element") }} href="/wordament" title="View Wordament" aria-label="View Wordament">
                        <img alt="Wordament" src="/images/game-tiles/wordament-tile.webp" aria-hidden="true" />
                    </a>
                </div>
                <div class='tile'>
                    <a onClick={() => { focusElementById("root-element") }} href="/promo/solitairemobileqr" target="_blank" title="Solitaire Mobile Downloads" aria-label="Solitaire Mobile Downloads">
                        <img alt="Solitaire Mobile Downloads" src="/images/game-tiles/solitaire-download-promo-tile.webp" aria-hidden="true" />
                    </a>
                </div>
            </div>
        </div>
    );
};
import { GamesData } from "./game-page.model";

export const gamesData: GamesData = {
    'jigsaw': {
        name: 'Microsoft Jigsaw',
        texts: [
            {
                heading: 'Microsoft Jigsaw',
                text: "With multiple game modes, downloadable puzzle packs, and the ability to create your own custom puzzles, you will never run out of things to do in Microsoft Jigsaw!"
            },
            {
                title: 'Classic Jigsaw Mode',
                text: "Play a wide variety of traditional jigsaw puzzles in Classic Jigsaw mode. Microsoft Jigsaw includes tons of themes and puzzle images to choose from, and you can select the number of pieces in the puzzle to make it easier or more difficult. You can zoom, automatically arrange pieces, and use special in-game trays to help sort pieces. If you enjoy real jigsaw puzzles, Microsoft Jigsaw is the best PC and tablet classic jigsaw experience you’ll find!"
            },
            {
                title: 'Jigsaw Jam',
                text: "Jigsaw Jam is a fun upbeat game mode that's excellent for beginners and experts alike! Place pieces one at a time while the timer ticks away - find the right fit for each piece quickly to earn bonus multipliers and get a high score! Jigsaw Jam is very easy to play, but will be challenging to master."
            },
            {
                title: 'Custom Puzzles',
                text: "Why not turn your favorite memory or funniest photo into a jigsaw puzzle? With Microsoft Jigsaw, you can import your own photos from your device or camera to use in your puzzles! You can even export your custom puzzles and share them with your family and friends."
            },
            {
                title: 'Daily Challenges',
                text: "The same Daily Challenge experience you know and love from our other Microsoft games on Windows 11 comes to Microsoft Jigsaw! Players receive a set of challenges each day, including fresh Jigsaw variants and new game modes. Complete enough Daily Challenges in a month to earn badges and compete with your friends."
            },
            {
                title: 'Xbox Support',
                text: "Log in with your Xbox account to earn achievements, compete with your friends, submit your scores to the leaderboards, and track your personal gameplay statistics. If you use your Xbox account to sign in, your games will be saved in the cloud, so you can play from any supported device."
            },
        ],

        images: {
            banner: '/images/banners/jigsaw-banner.png',
            screenshots: [
                {
                    image: '/images/game-screenshots/jigsaw-1-big.webp',
                    thumbnail: '/images/game-screenshots/jigsaw-1-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/jigsaw-2-big.webp',
                    thumbnail: '/images/game-screenshots/jigsaw-2-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/jigsaw-3-big.webp',
                    thumbnail: '/images/game-screenshots/jigsaw-3-thumb.webp',
                }
            ],
            qrCode: '/images/qr-codes/jigsaw-qr-code.png',
            gameIcon: '/images/game-icons/jigsaw-icon.png',
        },
        storeLinks: {
            microsoftStore: 'https://www.microsoft.com/en-us/store/p/microsoft-jigsaw/9wzdncrfj9x2',
            zoneMsnGame: 'https://zone.msn.com/en/jigsaw/default.htm',
        }
    },
    'mahjong': {
        name: 'Mahjong by Microsoft',
        texts: [
            {
                heading: 'Mahjong by Microsoft',
                text: "Enjoy gorgeous graphics, relaxing sounds, and the tile-matching fun of Mahjong on Windows 11. Or take the tranquil play of Mahjong with you with our mobile app, Mahjong by Microsoft – available on iOS or Android. Relax as you earn points completing beautiful mahjong puzzles. Complete Daily Challenges to earn monthly badges, use hints or the undo feature, and enjoy unique themes and tile sets as you solve hundreds of puzzles in the popular game of strategy, memory, and skill. Sign in with your Xbox account to collect achievements, save your progress, and climb the leaderboard in each game."
            },
            {
                title: 'On Windows 11:',
                text: "Choose from four levels of difficulty, perfect for Mahjong beginners and experts alike. Daily Challenges offer unique game play modes like Golden Tiles, where your only goal is to uncover and match the hidden pairs of golden tiles. Earn badges for the number of challenges you complete each month."
            },
            {
                title: 'On Mobile:',
                text: "Play five Daily Challenges offering additional game modes like Classic, Golden Tiles, Lightning Tiles, Match Attack and Score Attack. Turn on the timer to challenge your speed, or play at your own pace, unlocking backgrounds and tile sets as you progress. Bookmark your favorite puzzle boards to play again and again."
            },
            {
                text: "No matter where you play, escape on a peaceful journey in Mahjong by Microsoft, on Windows or Mobile."
            }
        ],
        images: {
            banner: '/images/banners/mahjong-banner.jpg',
            screenshots: [
                {
                    image: '/images/game-screenshots/mahjong-1-big.webp',
                    thumbnail: '/images/game-screenshots/mahjong-1-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/mahjong-2-big.webp',
                    thumbnail: '/images/game-screenshots/mahjong-2-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/mahjong-3-big.webp',
                    thumbnail: '/images/game-screenshots/mahjong-3-thumb.webp',
                }
            ],
            qrCode: '/images/qr-codes/mahjong-singular-qr-code.png',
            gameIcon: '/images/game-icons/mahjong-icon.png',
            phoneLarge: '/images/big-phones/big-phone-mahjong.png',
        },
        storeLinks: {
            microsoftStore: 'https://www.microsoft.com/en-us/store/p/microsoft-mahjong/9wzdncrfhwcp',
            zoneMsnGame: 'https://zone.msn.com/en/msmahjong/default.htm',
            iOSDesktopStore: 'https://aka.ms/mobilepg_mjios',
            androidDesktopStore: 'https://aka.ms/mobilepg_mjand',
            iOSMobileStore: 'https://aka.ms/mobilepg_mjios_store',
            androidMobileStore: 'https://aka.ms/mobilepg_mjand_store',
        }
    },

    'minesweeper': {
        name: 'Microsoft Minesweeper',
        texts: [
            {
                heading: 'Microsoft Minesweeper',
                text: "Featuring adjustable difficulty, classic Minesweeper gameplay, and Adventure mode, Microsoft Minesweeper is better than ever."
            },
            {
                title: 'Classic Mode',
                text: "Play the logic game you know and love, now with updated graphics and sound. Play with a touch screen or with a mouse and keyboard to mark the locations of all the mines without uncovering any of them! Start with an Easy puzzle and work your way up to Expert."
            },
            {
                title: 'Adventure Mode',
                text: "Players who want a different kind of challenge can try this game mode! Use your skills in Adventure mode to guide your brave hero to the center of Earth, collecting riches along the way. Blending traditional Minesweeper rules with fun new goals, Adventure Mode is a fresh take on a classic!"
            },
            {
                title: 'Daily Challenges',
                text: "Players receive new Challenges every day. Complete them to earn prizes and compete with your friends!"
            },
            {
                title: 'Xbox Integration',
                text: "Log in with your Xbox account to earn achievements, compete with your friends, and more. You can even play, pause, and resume your game on any Windows 11 device - start a game on one device, pause it, and pick up on another device where you left off!"
            },
            {
                text: "ESRB: E, PEGI: 3 (Mild Fantasy, Mild Violence)"
            }
        ],
        images: {
            banner: '/images/banners/minesweeper-banner.png',
            screenshots: [
                {
                    image: '/images/game-screenshots/minesweeper-1-big.webp',
                    thumbnail: '/images/game-screenshots/minesweeper-1-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/minesweeper-2-big.webp',
                    thumbnail: '/images/game-screenshots/minesweeper-2-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/minesweeper-3-big.webp',
                    thumbnail: '/images/game-screenshots/minesweeper-3-thumb.webp',
                }
            ],
            qrCode: '/images/qr-codes/minesweeper-qr-code.png',
            gameIcon: '/images/game-icons/minesweeper-icon.png',
            phoneLarge: '/images/big-phones/big-phone-minesweeper.png',
        },
        storeLinks: {
            microsoftStore: 'https://www.microsoft.com/en-us/store/p/microsoft-minesweeper/9wzdncrfhwcn',
            zoneMsnGame: 'https://zone.msn.com/en/msminesweeper/default.htm',
        }
    },

    'solitaire': {
        name: 'Microsoft Solitaire Collection',
        texts: [
            {
                heading: 'Microsoft Solitaire Collection',
                text: "Celebrating over 30 Years (since 1990) - Microsoft Solitaire remains one of the most played video games of ALL TIME! Simple rules and straightforward gameplay make it easy for anyone to pick up and play. With new features, collections, daily challenges and monthly events, Microsoft Solitaire Collection continues to be a digital sensation."
            },
            {
                title: 'Klondike',
                text: "This version is the timeless classic that many people just call 'Solitaire'. Try to clear all the cards from the table using one or three-card draw, while using traditional scoring or the Vegas scoring system."
            },
            {
                title: 'Spider',
                text: "Eight columns of cards await your attempts to clear them with the fewest moves possible. Start out playing with a single suit until you’re comfortable, and then see how you fare when using two or even all four suits in a game."
            },
            {
                title: 'FreeCell',
                text: "Use four extra cells to move cards around as you try to clear all cards from the table. More strategic than the Klondike version, FreeCell rewards players who think several moves ahead."
            },
            {
                title: 'TriPeaks',
                text: "Select cards in a sequence, either up or down, to earn points and clear the board. How many boards can you clear before you run out of deals?"
            },
            {
                title: 'Pyramid',
                text: "Pair two cards that add up to 13 in order to remove them from the board. Try to reach the top of the pyramid. See how many boards you can clear and how high you can score in this highly addictive card game!"
            },
            {
                title: 'Daily Challenges',
                text: "Players receive new challenges each day. Complete enough Daily Challenges in a month to earn badges and compete with your friends."
            },
            {
                title: 'Choose Your Theme',
                text: "The Microsoft Solitaire Collection features several beautiful themes, from the simplicity of 'Classic' to the serenity of an Aquarium that comes to life before you while you play. Now you can even create custom themes from your own photos!"
            },
            {
                title: 'Xbox Integration',
                text: "Log in with your Xbox account to earn achievements, compete with your friends, submit your scores to the leaderboards, and save your statistics and games in the cloud!"
            }
        ],
        images: {
            banner: '/images/banners/solitaire-banner.jpg',
            screenshots: [
                {
                    image: '/images/game-screenshots/solitaire-1-big.webp',
                    thumbnail: '/images/game-screenshots/solitaire-1-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/solitaire-2-big.webp',
                    thumbnail: '/images/game-screenshots/solitaire-2-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/solitaire-3-big.webp',
                    thumbnail: '/images/game-screenshots/solitaire-3-thumb.webp',
                }
            ],
            qrCode: '/images/qr-codes/solitaire-singular-qr-code.png',
            gameIcon: '/images/game-icons/solitaire-icon.png',
            phoneLarge: '/images/big-phones/big-phone-solitaire.png',
        },
        storeLinks: {
            microsoftStore: 'https://www.microsoft.com/en-us/store/p/microsoft-solitaire-collection/9wzdncrfhwd2',
            zoneMsnGame: 'https://zone.msn.com/en/mssolitairecollection/default.htm',
            iOSDesktopStore: 'https://aka.ms/mobilepg_solios',
            androidDesktopStore: 'https://aka.ms/mobilepg_soland',
            iOSMobileStore: 'https://aka.ms/mobilepg_solios_store',
            androidMobileStore: 'https://aka.ms/mobilepg_soland_store',
        }
    },

    'sudoku': {
        name: 'Microsoft Sudoku',
        texts: [
            {
                heading: 'Microsoft Sudoku',
                text: "Bringing the classic game you love to a whole new light! The #1 logic puzzle game has turned up the brilliance with fresh colors, exciting features, more levels of difficulty, daily challenges, achievements and MORE!"
            },
            {
                title: 'New Ways to Play',
                text: "Find new ways to play in Irregular & Ice Breaker Mode. Choose your theme to play with numbers or charms. Set your own level of difficulty, erase, undo, or take notes as you improve your skills, master new levels, and earn XP."
            },
            {
                text: "Keep your mind sharp with endless brain training puzzles for players of all ages!"
            }
        ],
        images: {
            banner: '/images/banners/sudoku-banner.png',
            screenshots: [
                {
                    image: '/images/game-screenshots/sudoku-1-big.webp',
                    thumbnail: '/images/game-screenshots/sudoku-1-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/sudoku-2-big.webp',
                    thumbnail: '/images/game-screenshots/sudoku-2-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/sudoku-3-big.webp',
                    thumbnail: '/images/game-screenshots/sudoku-3-thumb.webp',
                }
            ],
            qrCode: '/images/qr-codes/sudoku-onelink-qr-code.png',
            gameIcon: '/images/game-icons/sudoku-icon.png',
            phoneLarge: '/images/big-phones/big-phone-sudoku.png',
        },
        storeLinks: {
            microsoftStore: 'https://www.microsoft.com/en-us/store/p/microsoft-sudoku/9wzdncrfhv60',
            zoneMsnGame: 'https://zone.msn.com/en/sudoku/default.htm',
            iOSDesktopStore: 'https://aka.ms/mobilepg_sdios',
            androidDesktopStore: 'https://aka.ms/sudokuand',
            iOSMobileStore: 'https://aka.ms/mobilepg_sdios_store',
            androidMobileStore: 'https://aka.ms/mobilepg_sudokuand_store',
        }
    },

    'treasurehunt': {
        name: 'Microsoft Treasure Hunt',
        texts: [
            {
                heading: 'Microsoft Treasure Hunt',
                text: "Microsoft Treasure Hunt builds on Microsoft Minesweeper's Adventure mode. Mark suspicious terrain and plan your moves carefully to uncover tools, weapons, and even hidden bonus levels filled with loot. Using the gold you collect, purchase supplies and permanent upgrades for your journey deep underground."
            },
            {
                title: 'Exhilarating Adventure',
                text: "Intrepid treasure hunters will amass astonishing riches, but watch out! It will take all your wits to navigate the twists, turns, and traps that stand in your way! The expedition will take you deep down toward the center of the earth. How far will you go?"
            },
            {
                title: 'Xbox Integration',
                text: "Sign in with your Xbox account to earn achievements, show off your highest scores on the leaderboards, and save your game in the cloud to use across multiple devices."
            },
            {
                text: "ESRB: E, PEGI: 3. (Mild Fantasy, Mild Violence)"
            },
        ],
        images: {
            banner: '/images/banners/treasure-hunt-banner.png',
            screenshots: [
                {
                    image: '/images/game-screenshots/treasure-hunt-1-big.webp',
                    thumbnail: '/images/game-screenshots/treasure-hunt-1-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/treasure-hunt-2-big.webp',
                    thumbnail: '/images/game-screenshots/treasure-hunt-2-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/treasure-hunt-3-big.webp',
                    thumbnail: '/images/game-screenshots/treasure-hunt-3-thumb.webp',
                }
            ],
            qrCode: '/images/qr-codes/treasure-hunt-qr-code.png',
            gameIcon: '/images/game-icons/treasure-hunt-icon.png',
            phoneLarge: '/images/big-phones/big-phone-treasure-hunt.png',
        },
        storeLinks: {
            microsoftStore: 'https://www.microsoft.com/en-us/store/p/microsoft-treasure-hunt/9wzdncrfhw9g',
        }
    },

    'wordament': {
        name: 'Wordament',
        texts: [
            {
                heading: 'Wordament',
                text: `Wordament for PC has been rolled into an exciting bundle game from the Microsoft Casual Games Studio:`
            },
            {
                anchor: {
                    text: `Ultimate Word Games!`,
                    href: `/ultimatewordgames`
                }
            },
            {
                text: `You can still download the mobile version of Wordament for iOS and Android from the links on this page.`
            },
            {
                text: "Why play against one person in a turn when you can play against thousands at once? Wordament® is a real-time continuous word tournament. Players compete on the same board with everyone at once. Rounds offer challenges such as two- and three-letter tiles, themed words, speed rounds and more. You can be a champion in many ways: find the most words possible, earn the best score, find the longest words, best your Frenemies, or beat your top score. How you win is up to you."
            },
            {
                text: "Wordament tracks your progress by maintaining rich statistics about your gameplay including your best word found, total score, best word count, first place finishes and more. Leaderboards show you who's rocking it for the day, hour and overall. Climb the ranks and earn achievements and bragging rights along the way. Will you be our next champion?"
            },
        ],
        images: {
            banner: '/images/banners/wordament-banner.png',
            screenshots: [
                {
                    image: '/images/game-screenshots/wordament-1-big.webp',
                    thumbnail: '/images/game-screenshots/wordament-1-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/wordament-2-big.webp',
                    thumbnail: '/images/game-screenshots/wordament-2-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/wordament-3-big.webp',
                    thumbnail: '/images/game-screenshots/wordament-3-thumb.webp',
                }
            ],
            qrCode: '/images/qr-codes/wordament-onelink-qr-code.png',
            gameIcon: '/images/game-icons/wordament-icon.png',
            phoneLarge: '/images/big-phones/big-phone-wordament.png',
        },
        storeLinks: {
            microsoftStore: 'https://www.microsoft.com/en-us/store/p/microsoft-ultimate-word-games/9wzdncrfhwfg',
            zoneMsnGame: 'https://zone.msn.com/en/mswordament/default.htm',
            iOSDesktopStore: 'https://aka.ms/mobilepg_wordios',
            androidDesktopStore: 'https://aka.ms/mobilepg_wordand',
            iOSMobileStore: 'https://aka.ms/mobilepg_wordios_store',
            androidMobileStore: 'https://aka.ms/mobilepg_wordand_store',
        }
    },
    'ultimatewordgames': {
        name: 'Microsoft Ultimate Word Games',
        texts: [
            {
                heading: 'Microsoft Ultimate Word Games',
                text: "Check out the latest addition to the Microsoft Casual Games product family! Microsoft Ultimate Word Games is the ultimate collection of the three best word games for Windows 11, including Wordament, Word Twister, and Crosswords. With multiple ways to play each game mode and Daily Challenges, Microsoft Ultimate Word Games is a great way to exercise your brain and relax while having fun!"
            },
            {
                title: 'Crosswords',
                text: "The best Crosswords game you’ll find anywhere! With multiple board sizes, difficulties, and plenty of options, you can play Crosswords exactly the way you want!"
            },
            {
                title: 'Wordament',
                text: "The evolution of the hit-game on Windows, iOS, and Android. Find as many words as you can on a 4x4 grid of letter tiles. Play single player or multiplayer versus thousands of players at once!"
            },
            {
                title: 'Word Twister',
                text: "Word Twister is a super-addictive game where you are given a rack of letter tiles, and your goal is to create words using them."
            },
            {
                title: 'Single Player Adventures',
                text: "Each game mode has multiple adventure maps where you can earn 3 possible stars on each puzzle. Earn at least one star to unlock the next puzzle and see if you can reach the treasure at the end of each map!"
            },
            {
                title: 'Daily Challenges',
                text: "Each game has a unique Daily Challenge every day. Complete enough Daily Challenges in a month to earn special badges."
            },
            {
                title: 'Xbox Integration',
                text: "Sign in with your Xbox account to earn achievements, compete with your friends on the leaderboards, and track your personal gameplay statistics. If you sign in with a Xbox account, your progress is stored in the cloud so you can play the game anywhere no matter what happens to your device!"
            }
        ],
        images: {
            banner: '/images/banners/ultimate-word-games-banner.png',
            screenshots: [
                {
                    image: '/images/game-screenshots/ultimate-word-games-1-big.webp',
                    thumbnail: '/images/game-screenshots/ultimate-word-games-1-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/ultimate-word-games-2-big.webp',
                    thumbnail: '/images/game-screenshots/ultimate-word-games-2-thumb.webp',
                },
                {
                    image: '/images/game-screenshots/ultimate-word-games-3-big.webp',
                    thumbnail: '/images/game-screenshots/ultimate-word-games-3-thumb.webp',
                }
            ],
            qrCode: '/images/qr-codes/ultimate-word-games-qr-code.png',
            gameIcon: '/images/game-icons/ultimate-word-games-icon.png',
            phoneLarge: '/images/big-phones/big-phone-ultimate-word-games.png',
        },
        storeLinks: {
            microsoftStore: 'https://www.microsoft.com/en-us/store/p/microsoft-ultimate-word-games/9wzdncrfhwfg',
            zoneMsnGame: 'https://zone.msn.com/en/msultimateword/default.htm',
        }
    }
}
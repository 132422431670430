import { Component, createSignal, onMount } from 'solid-js';
import { HeaderHTML } from './header-component.html';

// TODO: The functionality of the submenu could be simplified further by leveraging SolidJs tooling.
export const Header: Component = () => {
    const [isSubmenuOpen, setIsSubmenuOpen] = createSignal(false);
    const [currentSubmenuFocusIndex, setCurrentSubmenuFocusIndex] = createSignal(-1);

    let initialSubmenuTouchHotfix: boolean = false;
    let submenuToggleButton: HTMLButtonElement;
    let gamesSubmenu: HTMLElement;
    let submenuLinkArray: HTMLAnchorElement[];

    onMount(async () => {
        initialize();
    });

    function initialize() {
        submenuToggleButton = document.querySelector('#header-games-submenu-toggle-button') as HTMLButtonElement;
        gamesSubmenu = document.querySelector('#header-games-submenu') as HTMLElement;
        submenuLinkArray = Array.from(gamesSubmenu.querySelectorAll('a'));

        window.addEventListener('keydown', (event: KeyboardEvent) => { onWindowKeyDownEvent(event); });
    }

    function onWindowKeyDownEvent(event: KeyboardEvent) {
        // Close submenu
        if (event.key === 'Escape') { closeSubmenu(); }

        // Navigate submenu with up/down keys
        if (event.key === 'ArrowUp' && isSubmenuOpen() == true) {
            event.preventDefault();
            setCurrentSubmenuFocusIndex(currentSubmenuFocusIndex() - 1);
            if (currentSubmenuFocusIndex() < 0) {
                // currentSubmenuFocusIndex = 7;
                setCurrentSubmenuFocusIndex(7); // TODO: Get Length of submenuLinkArray
            }
            submenuLinkArray[currentSubmenuFocusIndex()].focus();
        }
        if (event.key === 'ArrowDown' && isSubmenuOpen() == true) {
            event.preventDefault();
            setCurrentSubmenuFocusIndex(currentSubmenuFocusIndex() + 1);
            if (currentSubmenuFocusIndex() > 7) { // TODO: Get Length of submenuLinkArray
                setCurrentSubmenuFocusIndex(0);
            }
            submenuLinkArray[currentSubmenuFocusIndex()].focus();
        }

        // Click submenu items with the spacebar
        // The enter key also works automatically
        if (event.key === ' ' && isSubmenuOpen() == true) {
            event.preventDefault();
            submenuLinkArray[currentSubmenuFocusIndex()]?.click();
        }
    }


    function onSubmenuFocusOutEvent(event: FocusEvent) {
        const target = event.currentTarget as HTMLElement
        if (target && !target.contains(event.relatedTarget as Node)) { closeSubmenu(); }
    }

    function touchSubmenuDown() {
        if (!isSubmenuOpen()) {
            initialSubmenuTouchHotfix = true;
            openSubmenu();
        }
    }

    function touchSubmenuUp() {
        setTimeout(() => { initialSubmenuTouchHotfix = false; }, 1);
    }

    function openSubmenu() {
        if (isSubmenuOpen()) { return; }
        // gamesSubmenu.style.padding = '20% 0px 0px 0px';
        gamesSubmenu.style.height = gamesSubmenu.scrollHeight + 'px';
        gamesSubmenu.setAttribute('aria-hidden', 'false');
        submenuToggleButton.setAttribute('aria-expanded', 'true');
        gamesSubmenu.querySelectorAll('ul li a').forEach((link) => {
            link.setAttribute('tabindex', '0');
        })
        setIsSubmenuOpen(true);
    }

    function closeSubmenu() {
        if (!isSubmenuOpen() || initialSubmenuTouchHotfix) { return; }
        // gamesSubmenu.style.padding = '0px';
        gamesSubmenu.style.height = '0px';
        gamesSubmenu.setAttribute('aria-hidden', 'true');
        submenuToggleButton.setAttribute('aria-expanded', 'false');
        gamesSubmenu.querySelectorAll('ul li a').forEach((link) => {
            link.setAttribute('tabindex', '-1');
        })
        setIsSubmenuOpen(false);
    }

    function toggleSubmenu() {
        if (isSubmenuOpen()) {
            closeSubmenu();
        } else {
            openSubmenu();
        }
    }

    return HeaderHTML({ openSubmenu, closeSubmenu, toggleSubmenu, touchSubmenuUp, touchSubmenuDown, onSubmenuFocusOutEvent } as HeaderHTMLProperties);
};

export interface HeaderHTMLProperties {
    openSubmenu: () => void,
    closeSubmenu: () => void,
    toggleSubmenu: () => void,
    touchSubmenuUp: () => void,
    touchSubmenuDown: () => void,
    onSubmenuFocusOutEvent: () => void,
};

import { Component, For, Show, } from 'solid-js';
import "./game-page.scss";
import { HomepageTiles } from '../../components/homepage-tiles/homepage-tiles-component';
import { gamesData } from './game-page.data';
import { GamePageHTMLProperties } from './game-page';

export const GamePageHTML: Component = (properties) => {
    const {
        urlParameters,
        selectedScreenshot,
        setSelectedScreenshot,
    } = properties as GamePageHTMLProperties

    return (
        <div class="game-page">
            <div class="game-page-content">
                <div class="banner-image" style={{ "background-image": "url(" + gamesData[urlParameters.gameId].images.banner + ")" }}></div>

                <div class="game-content">
                    <Show when={gamesData[urlParameters.gameId].images.screenshots}>
                        <div class="screenshots">
                            {/* "Object is possibly 'undefined'". Intellisense doesn't recognize that this is accounted for, and I'm not sure how to fix it. */}
                            <img class="screenshot" src={gamesData[urlParameters.gameId].images.screenshots[selectedScreenshot()].image}
                                alt={'Screenshot ' + (selectedScreenshot() + 1)} />

                            <div class="thumbnails">
                                <For each={gamesData[urlParameters.gameId].images.screenshots}>{(screenshot, index) =>
                                    <button class={"thumbnail" + (selectedScreenshot() === index() ? " thumbnail_selected" : "")} onclick={() => setSelectedScreenshot(index())} title={'View screenshot ' + (index() + 1)}>
                                        <img src={screenshot.thumbnail} alt={'Thumbnail ' + (index() + 1)} />
                                    </button>}
                                </For>
                            </div>
                        </div>
                    </Show>

                    <div class="text-container" tabindex="0">
                        <For each={gamesData[urlParameters.gameId].texts}>{(text, index) =>
                            <>
                                <Show when={text.heading}><h1>{text.heading}</h1></Show>
                                <Show when={text.title}><h2>{text.title}</h2></Show>
                                <Show when={text.text}><p>{text.text}</p></Show>
                                <Show when={text.anchor}><a href={text.anchor?.href}>{text.anchor?.text}</a></Show>
                            </>
                        }</For>
                    </div>

                    <Show when={Object.keys(gamesData[urlParameters.gameId].storeLinks).length > 0}>
                        <div class="store-container">
                            <h2>Play Now!</h2>
                            <div class='buttons-wrapper'>
                                <div class='buttons'>
                                    <Show when={gamesData[urlParameters.gameId].storeLinks.microsoftStore}>
                                        <a target="_blank" href={gamesData[urlParameters.gameId].storeLinks.microsoftStore} title={"Get " + gamesData[urlParameters.gameId].name + " from Microsoft"}>
                                            <img src="/images/store-buttons/get-it-from-microsoft-button.png" alt={"Get " + gamesData[urlParameters.gameId].name + " from Microsoft"} />
                                        </a>
                                    </Show>
                                    <Show when={gamesData[urlParameters.gameId].storeLinks.zoneMsnGame}>
                                        <a target="_blank" href={gamesData[urlParameters.gameId].storeLinks.zoneMsnGame} title={"Play " + gamesData[urlParameters.gameId].name + " Online Now at MSN Games"}>
                                            <img src="/images/store-buttons/msn-games-button.png" alt={"Play " + gamesData[urlParameters.gameId].name + " Online Now at MSN Games"} />
                                        </a>
                                    </Show>
                                    <Show when={gamesData[urlParameters.gameId].storeLinks.iOSDesktopStore}>
                                        <a class="desktop-only" target="_blank" href={gamesData[urlParameters.gameId].storeLinks.iOSDesktopStore} title={"Download " + gamesData[urlParameters.gameId].name + " for iOS"}>
                                            <img src="/images/store-buttons/app-store-button.svg" alt={"Download " + gamesData[urlParameters.gameId].name + " for iOS"} />
                                        </a>
                                    </Show>
                                    <Show when={gamesData[urlParameters.gameId].storeLinks.androidDesktopStore}>
                                        <a class="desktop-only" target="_blank" href={gamesData[urlParameters.gameId].storeLinks.androidDesktopStore} title={"Download " + gamesData[urlParameters.gameId].name + " for Android"}>
                                            <img src="/images/store-buttons/google-play-button.png" alt={"Download " + gamesData[urlParameters.gameId].name + " for Android"} />
                                        </a>
                                    </Show>
                                    <Show when={gamesData[urlParameters.gameId].storeLinks.iOSMobileStore}>
                                        <a class="mobile-only" target="_blank" href={gamesData[urlParameters.gameId].storeLinks.iOSMobileStore} title={"Download " + gamesData[urlParameters.gameId].name + " for iOS"}>
                                            <img src="/images/store-buttons/app-store-button.svg" alt={"Download " + gamesData[urlParameters.gameId].name + " for iOS"} />
                                        </a>
                                    </Show>
                                    <Show when={gamesData[urlParameters.gameId].storeLinks.androidMobileStore}>
                                        <a class="mobile-only" target="_blank" href={gamesData[urlParameters.gameId].storeLinks.androidMobileStore} title={"Download " + gamesData[urlParameters.gameId].name + " for Android"}>
                                            <img src="/images/store-buttons/google-play-button.png" alt={"Download " + gamesData[urlParameters.gameId].name + " for Android"} />
                                        </a>
                                    </Show>
                                </div>
                            </div>
                        </div>
                    </Show>

                    <h2>Other Games</h2>
                </div>
            </div>

            <HomepageTiles></HomepageTiles>
        </div>
    );
};
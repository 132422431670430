import { ApplicationInsights, ICustomProperties, IEventTelemetry, IPageViewTelemetry, ITelemetryItem } from '@microsoft/applicationinsights-web';

class ApplicationInsightsService {
    public applicationInsightsMicrosoft = new ApplicationInsights({
        config: {
            disableAjaxTracking: false,
            disableFetchTracking: false,
            instrumentationKey: import.meta.env.VITE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
            maxBatchInterval: 0
        },
    });

    constructor() {
        this.initialize();
    }

    initialize() {
        this.applicationInsightsMicrosoft.loadAppInsights();
        this.applicationInsightsMicrosoft.addTelemetryInitializer(this.mcgAugmentExceptions);
    }

    trackPageView(pageView?: IPageViewTelemetry | undefined) {
        this.applicationInsightsMicrosoft.trackPageView();
    }

    trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties | undefined) {
        this.applicationInsightsMicrosoft.trackEvent(event, customProperties);
    }

    mcgAugmentExceptions = function (envelope: ITelemetryItem): boolean {
        if (envelope && envelope.baseData && envelope.baseType === 'ExceptionData') {
            const exceptionProperties = envelope.baseData.properties || {};
            exceptionProperties.url = window.location.href;
            exceptionProperties.referrer = !!document.referrer ? document.referrer : 'Not Available';
            envelope.baseData.properties = exceptionProperties;
        }
        return true;
    }
}

const applicationInsightsService = new ApplicationInsightsService();
export default applicationInsightsService;
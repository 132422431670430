import { Component, onMount } from 'solid-js';
import './about-page.scss';
import { AboutPageHTML } from './about-page.html';
import applicationInsightsService from '../../services/application-insights-service';

export const AboutPage: Component = () => {
    onMount(async () => {
        initialize();
    });

    function initialize() {
        applicationInsightsService.trackPageView();
    }

    return AboutPageHTML({} as AboutPageHTMLProperties);
};

export interface AboutPageHTMLProperties {
};
import { Accessor, Component, Setter, createSignal, onMount } from 'solid-js';
import { MultipleGameQRPageHTML } from './multiple-game-qr-page.html';
import applicationInsightsService from '../../services/application-insights-service';

export const MultipleGameQR: Component = () => {
    const [selectedGame, setSelectedGame] = createSignal('');

    onMount(async () => {
        initialize();
    });

    function initialize() {
        applicationInsightsService.trackPageView();
    };

    return MultipleGameQRPageHTML({ selectedGame, setSelectedGame } as MultipleGameQRPageHTMLProperties);
};

export interface MultipleGameQRPageHTMLProperties {
    selectedGame: Accessor<string>,
    setSelectedGame: Setter<string>,
};